// src/AuthContext.js

import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import URL from "../../URL";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

const base64UrlDecode = (base64Url) => {
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonStr = Buffer.from(base64, "base64").toString("utf-8");
  return JSON.parse(jsonStr);
};

const AuthProvider = ({ children }) => {
  //const navigate = useNavigate();
  const isTokenExpired = () => {
    let token = localStorage.getItem("access_token");

    if (!token) {
      return true;
    }

    const tokenParts = token.split(".");
    if (tokenParts.length !== 3) {
      // Invalid token format
      return true;
    }

    const payload = base64UrlDecode(tokenParts[1]);

    if (!payload.exp) {
      // Token doesn't have an expiration claim
      return false;
    }

    const currentTime = Math.floor(Date.now() / 1000);
    return payload.exp < currentTime;
  };

  const user = () => {
    let token = localStorage.getItem("access_token");

    if (!token) {
      return null;
    }

    return jwtDecode(token);
  };

  const [authState, setAuthState] = useState({
    isAuthenticated: !isTokenExpired(),
    user: user(),
    refreshToken: null,
  });

  const login = (token = null) => {
    setAuthState({
      isAuthenticated: true,
      user: token
        ? jwtDecode(token)
        : jwtDecode(localStorage.getItem("access_token")),
    });
  };

  const logout = () => {
    localStorage.clear();
    setAuthState({
      isAuthenticated: false,
      user: null,
      refreshToken: null,
    });
    // Redirect to the login page
    // You can replace '/login' with the appropriate login route
    // navigate("/");
  };

  const HasResource = (resourceName) => {
    try {
      return authState?.user?.roles.hasOwnProperty(resourceName);
    } catch (error) {
      console.error(`Error checking resource: ${error}`);
      return false;
    }
  };

  const HasPermission = (resourceName, permission) => {
    try {
      if (HasResource(resourceName)) {
        return authState?.user?.roles[resourceName].includes(permission);
      }
      return false;
    } catch (error) {
      console.error(`Error checking permission: ${error}`);
      return false;
    }
  };

  const refreshAccessToken = async () => {
    try {
      // Make sure a refresh request is not already in progress
      if (!authState.refreshToken) {
        const refreshToken = localStorage.getItem("refresh_token");
        setAuthState((prevAuthState) => ({
          ...prevAuthState,
          refreshToken: refreshToken,
        }));

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${URL}api/jwt/refresh`,
          headers: {
            Authorization: "Bearer " + refreshToken,
          },
        };
        const response = await axios.request(`${URL}api/jwt/refresh`, config);
        const newAccessToken = response.data.access_token;
        localStorage.setItem("access_token", newAccessToken);
        login(newAccessToken);
        return newAccessToken;
      }
    } catch (error) {
      // Logout user and redirect to login page
      logout();
      return null;
    } finally {
      // Clear refresh token state
      setAuthState((prevAuthState) => ({
        ...prevAuthState,
        refreshToken: null,
      }));
    }
  };

  useEffect(() => {
    //     // Add a response interceptor
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        // Check if the error is due to an expired token
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          try {
            const refreshedToken = await refreshAccessToken();

            // Update the original request with the new token
            originalRequest.headers.Authorization = `Bearer ${refreshedToken}`;
            return axios(originalRequest);
          } catch (refreshError) {
            originalRequest._retry = false;
            return axios(originalRequest);
            // Handle refresh token error (e.g., redirect to login)
            // console.error('Error handling token refresh:', refreshError);
            // throw refreshError;
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      // Cleanup the interceptor when the component unmounts
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [authState]);

  const contextValue = {
    authState,
    login,
    logout,
    refreshAccessToken,
    isTokenExpired,
    HasResource,
    HasPermission,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
