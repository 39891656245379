import React from "react";
import RequireAuthGuard from "./components/auth/RouteGard";
import { createBrowserRouter } from "react-router-dom";

// Lazy load the components
const HIPDActivities = React.lazy(() =>
  import("./layout/GlobalOperations/Activities/HIPD-activities/HIPDActivities")
);
const GDActivities = React.lazy(() =>
  import("./layout/GlobalOperations/Activities/GD-activities/GDActivities")
);
const ProgramActivities = React.lazy(() =>
  import(
    "./layout/GlobalOperations/Activities/ProgramActivities/ProgramActivities"
  )
);
const HIPD = React.lazy(() => import("./layout/hipdEntered/hipdEntered"));
const FetchFeedBack = React.lazy(() => import("./layout/feedback"));
const Home = React.lazy(() => import("./layout/home/home"));
const Dashboard = React.lazy(() => import("./layout/dashboard/dashboard"));
const OverseasActivities = React.lazy(() =>
  import("./layout/OverseasesEntry/OverseasActivities")
);
const Earth = React.lazy(() => import("./layout/overview/overview"));
const GlobalOperations = React.lazy(() =>
  import("./layout/GlobalOperations/ResultChain/GlobalOperationsActivities")
);
const ChatApp = React.lazy(() => import("./layout/MhgAI/MhgAI"));
const Complains = React.lazy(() => import("./layout/complains/complains"));
const MonitoringActionTracker = React.lazy(() =>
  import("./layout/MonitoringActionTracker/MonitoringActionTracker")
);
const Governance = React.lazy(() => import("./layout/Governance/Governance"));
const OverseasActivitiesReport = React.lazy(() =>
  import("./layout/OverseasActivitiesReport/overseasactivitiesreport")
);
// const HIPD = React.lazy(() => import("./layout/hipdEntered/hipdEntered"));
const MHEMS = React.lazy(() => import("./layout/Mhems/mhems"));
const Directory = React.lazy(() => import("./layout/Directory/directory"));
const Activation = React.lazy(() => import("./layout/Account/Activation"));
const Recovery = React.lazy(() => import("./layout/Account/Recovery"));
const RecoveryConfirmation = React.lazy(() =>
  import("./layout/Account/RecoveryConfirmation")
);
const Profile = React.lazy(() => import("./layout/Account/Profile"));
const ProjectLog = React.lazy(() => import("./layout/ProjectLog/ProjectLog"));
const RiskRegister = React.lazy(() =>
  import("./layout/RiskRegisterReport/RiskRegisterReport")
);
const MealPlanning = React.lazy(() =>
  import("./layout/meal-planning/index.jsx")
);
const CreateMealPlan = React.lazy(() =>
  import("./layout/meal-planning/CreateMealPlan.jsx")
);
const UpdateMealPlan = React.lazy(() =>
  import("./layout/meal-planning/UpdateMealPlan.jsx")
);
const QualitativeAssessments = React.lazy(() =>
  import("./layout/QualitativeAssessments/QualitativeAssessment")
);
const ManageQAssessments = React.lazy(() =>
  import("./layout/QualitativeAssessments/listQA")
);
const SMTDashboard = React.lazy(() =>
  import("./layout/SMTDashboard/SMTDashboard")
);
const GlobalResultsFramework = React.lazy(() =>
  import("./layout/GlobalResultsFramework/GlobalResultsFramework")
);
const DepartmentActivities = React.lazy(() =>
  import("./layout/GlobalOperations/Activities/DepartmentActivities")
);
const PMTDashboard = React.lazy(() => import("./layout/PMTDashboard"));
const CallForProposal = React.lazy(() =>
  import(
    "./layout/PMTDashboard/pages/general-pages/call-for-proposal/call-for-proposal"
  )
);
const CreateConceptNote = React.lazy(() =>
  import(
    "./layout/PMTDashboard/pages/general-pages/create-concept-note/create-concept-note"
  )
);
const AuthLayout = React.lazy(() => import("./layout/auth-layout/auth-layout"));
const ErrorComponent = React.lazy(() =>
  import("./components/PMTDashboard/common/error-component")
);
const ProjectDetails = React.lazy(() =>
  import("./layout/PMTDashboard/pages/general-pages/proposal-details")
);
const FranceResultsFramework = React.lazy(() =>
  import("./layout/FranceResultsFramework/FranceResultsFramework")
);

const CreateProposal = React.lazy(() =>
  import(
    "./layout/PMTDashboard/pages/general-pages/create-proposal/create-proposal"
  )
);
const FranceFrameWork = React.lazy(() => import("./layout/france-framework"));
const ScoringTemplate = React.lazy(() =>
  import("./layout/PMTDashboard/pages/tabs-pages/scoring-template")
);
const HumanResourceEdit = React.lazy(() =>
  import(
    "./layout/PMTDashboard/pages/general-pages/proposal-details/human-resource-edit"
  )
);
const ProcurementPlan = React.lazy(() =>
  import(
    "./layout/PMTDashboard/pages/general-pages/proposal-details/procurement-plan"
  )
);
const AssetRegister = React.lazy(() =>
  import(
    "./layout/PMTDashboard/pages/general-pages/proposal-details/asset-register"
  )
);

const RiskRegisterDetails = React.lazy(() =>
  import(
    "./layout/PMTDashboard/pages/general-pages/proposal-details/risk-register"
  )
);

const MealReporting = React.lazy(() =>
  import(
    "./layout/PMTDashboard/pages/general-pages/proposal-details/meal-reporting"
  )
);

const ChangeRequestForm = React.lazy(() =>
  import(
    "./layout/PMTDashboard/pages/general-pages/proposal-details/change-request-form"
  )
);

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthLayout />,
    errorElement: <ErrorComponent />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "forget-password",
        element: <Recovery />,
      },
      {
        path: "recovery-confirmation",
        element: <RecoveryConfirmation />,
      },
      {
        path: "confirmation",
        element: <Activation />,
      },
    ],
  },
  {
    path: "/",
    errorElement: <ErrorComponent />,
    element: (
      <RequireAuthGuard>
        <Dashboard />
      </RequireAuthGuard>
    ),
    children: [
      {
        path: "pmt-dashboard",
        element: <PMTDashboard />,
        errorElement: <ErrorComponent />,
        children: [
          {
            path: ":id",
            element: <ProjectDetails />,
          },
          {
            path: "call-for-proposal",
            element: <CallForProposal />,
          },
          {
            path: "create-concept-note",
            element: <CreateConceptNote />,
          },
          {
            path: "create-proposal",
            element: <CreateProposal />,
          },
          {
            path: "scoring-template",
            element: <ScoringTemplate />,
          },
          {
            path: "asset-register",
            element: <AssetRegister />,
          },
          {
            path: "risk-register",
            element: <RiskRegisterDetails />,
          },
          {
            path: "meal-reporting",
            element: <MealReporting />,
          },
          {
            path: "human-resource",
            element: <HumanResourceEdit />,
          },
          {
            path: "procurement-plan",
            element: <ProcurementPlan />,
          },
          {
            path: "change-request-form",
            element: <ChangeRequestForm />,
          },
        ],
      },
      {
        path: "overview",
        element: <Earth />,
      },

      {
        path: "overseas-activities",
        element: <OverseasActivities />,
      },
      {
        path: "os-report",
        element: <OverseasActivitiesReport />,
      },
      {
        path: "generative-ai",
        element: <ChatApp />,
      },
      {
        path: "complains",
        element: <Complains />,
      },
      {
        path: "pmf-tracker",
        element: <MonitoringActionTracker />,
      },
      {
        path: "governance",
        element: <Governance />,
      },
      {
        path: "hipd",
        element: <HIPD />,
      },
      {
        path: "meetings",
        element: <MHEMS />,
      },
      {
        path: "directory",
        element: <Directory />,
      },
      {
        path: "project-logs",
        element: <ProjectLog />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "risk-register",
        element: <RiskRegister />,
      },
      {
        path: "meal-planning",
        element: <MealPlanning />,
        children: [
          {
            path: "create-meal-plan",
            element: <CreateMealPlan />,
          },
          {
            path: "update-meal-plan/:id",
            element: <UpdateMealPlan />
          }
        ],
      },
      {
        path: "qualitative-assessments",
        element: <QualitativeAssessments />,
      },
      {
        path: "manage-assessments",
        element: <ManageQAssessments />,
      },
      {
        path: "trustees-dashboard",
        element: <SMTDashboard />,
      },
      {
        path: "global-framework",
        element: <GlobalResultsFramework />,
      },
      {
        path: "france-framework",
        element: <FranceFrameWork />,
      },
      {
        path: "department-activities",
        element: <DepartmentActivities />,
      },
      {
        path: "HIPD-activities",
        element: <HIPDActivities />,
      },
      {
        path: "GD-activities",
        element: <GDActivities />,
      },
      {
        path: "uk-programs",
        element: <ProgramActivities />,
      },
      {
        path: "global-operations",
        element: <GlobalOperations />,
      },
      {
        path: "france-results-framework",
        element: <FranceResultsFramework />,
      },
      {
        path: "feedback",
        element: <FetchFeedBack />,
      },
    ],
  },
]);
